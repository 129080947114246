// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mix: '136e4e22f78c0b344767cd39f9ca0d9c',
  url: 'https://bookingenginesandbox.ezyinn.net',
  backendurl: 'https://pms.ezyinn.net/ezyinn/',
  socketbackendurl: '/',
  S3AccessKeyID: 'AKIA3ACYYOBHXFYD4JM6',
  S3SecretAccessKey: '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',
  S3URL: 'https://ezyinnbucket.s3.us-west-1.amazonaws.com/',
  LOGODIR: 'LOGODIR/',
  COVERDIR: 'COVERDIR/',
  PROFILEDIR: 'PROFILEDIR/',
  Documents: 'Documents/',
  ROOMIMGDIR: 'ROOMIMGDIR/',
  CARDDECRYPT: 'manakravironakshereeeeeeeee1008!',
  SHIFT4_URL: 'https://i4m.shift4test.com',
  STRIPE_PRIV_KEY:
    'pk_test_51McSnsHzziVBvHPCtiy5aNhAaDOg3iTF1eGDVo9NJFevexqO5NtOUVFFUFQjqc5oAn2vrFMSzHfkf4FOnt2SaoyY00X0wv9eMf',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
